/* eslint-disable no-case-declarations */
import _ from "lodash";
import MessageTypes from "enums/message-types";
import { setRedirect, setLoader, setOrigin } from "redux/actions/ui";
import { apiRequest, API_SUCCESS, API_ERROR } from "redux/actions/api";
import {
  setData,
  // putData,
  // updateItemData,
  // updateItemWithItem,
  removeData,
  // updateItemOfArrayOfObject,
  clearData,
  appendDataToObjectItem,
  // manipulateData,
} from "redux/actions/data";
import { setMessage } from "redux/actions/message";
import { setAuth } from "redux/actions/auth";
import { setNotification } from "redux/actions/notifications";
import { setToStorage, removeFromStorage } from "redux/actions/storage";
import { setHeader, clearHeader } from "redux/actions/header";
import { sendLog } from "redux/actions/log";
import SeverityTypes from "enums/severity-types";
import {
  START_ADD_OR_EDIT_ASSIGNMENT,
  GET_ASSIGNMENT_STUDENTS,
  GET_AUDIO_UPLOAD_PARAMS,
  REMOVE_AUDIO_UPLOAD_PARAMS,
  SAVE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  SAVE_UNSAVED_ASSIGNMENT,
  START_ASSIGNMENT_LIST,
  DELETE_ASSIGNMENT,
  GET_ASSIGNMENT_DETAILS,
  GET_QUIZ_LIST_OF_BOOK,
  GET_MATERIAL_LIST_OF_BOOK,
  GET_QUIZ_DETAIL,
  CREATE_NEW_MESSAGE,
  CREATE_MESSAGE_TO_USERS,
  GET_MESSAGE_LIST,
  SIGNIN_FEATURE,
  START_SIGNIN_PAGE,
  SIGNIN,
  SELECT_SIDENAV_ITEM,
  SIGNOUT,
  GET_SEARCH_BOOKS,
  NAVBAR,
  CREATE_PASSWORD_PAGE,
  START_CREATE_PASSWORD_PAGE,
  VALIDATE_EMAIL_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD_PAGE,
  START_FORGOT_PASSWORD_PAGE,
  SEND_LINK_BY_EMAIL,
  START_HOME_PAGE,
  GET_HOME_ASSIGNMENT_DETAILS,
  GET_ALL_BOOKS_WITH_FILTER_LOOKUPS,
  GET_ALL_BOOKS_WITH_FILTER,
  GET_MY_CLASSES,
  GET_MY_STUDENTS,
  GET_MY_STUDENTS_LOOKUPS,
  UPDATE_MY_STUDENT,
  REGENERATE_PASSWORD,
  REMOVE_PASSWORD_GENERATION_RESULT,
  GET_MY_PROFILE,
  UPDATE_MY_PROFILE,
  GET_UPLOAD_PARAMS,
  REMOVE_UPLOAD_PARAMS,
  CHANGE_PASSWORD,
  GET_TABS_LOOKUPS,
  START_CALENDAR,
  GET_STUDENT_REPORTS,
  GET_CLASS_STUDENT_REPORTS,
  GET_GENERAL_CLASS_REPORTS,
  GET_SCHOOL_CLASS_REPORTS,
  GET_GENERAL_SCHOOL_CLASS_REPORTS,
  GET_SCHOOL_CLASS_STUDENT_REPORTS,
  GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS,
  GET_ALL_ACADEMIC_YEAR_ITEMS,
  GET_USER_GRADES,
  GET_ALL_ACADEMIC_YEAR_DETAILS,
} from "redux/actions/pages";

// LOOKUP KEYS
import {
  TOKEN_STORAGE_KEY,
  SIGNIN_ROUTE,
  BASE_URL,
  PRODUCT_TYPE,
  GRADES,
  LEVELS,
  COURSES,
  SKILLS,
  UNITS,
  OBJECTIVES,
  SPECIAL_DAYS,
} from "redux/actions/constants";
import UserTypes from "enums/user-types";
// import StateOperationTypes from "enums/state-operation-types";
// import FinderTypes from "enums/finder-types";

// Route Part + Url + Error Message
// ALL BOOKS
// const GET_BOOKS_URL = `${BASE_URL}/api/book/extended`;
// const GET_ALL_BOOKS_API_ERROR_MSG =
//   "An error occured while all books are gotten. Please try again later.";
const GET_ALL_BOOKS_WITH_FILTER_URL = `${BASE_URL}/api/book/get-all-books-with-filter`;
const GET_ALL_BOOKS_WITH_FILTER_ERROR_MSG = "Kitaplar getirilirken hata meydana geldi.";
const GET_MY_CLASSES_URL = `${BASE_URL}/api/class-user/get-my-classes`;

const GET_LOOKUPS_DETAILED_URL = `${BASE_URL}/api/lookup/get-lookups-detailed`;
const GET_LOOKUPS_DETAILED_API_ERROR_MSG =
  "An error occured while selection options are gotten. Please try again later.";

// SIGNIN
const SIGNIN_URL = `${BASE_URL}/api/user/signin`;
const DASHBOARD_ROUTE = "/home";

// SIGNOUT
const SIGNOUT_URL = `${BASE_URL}/api/user/signout`;
const GET_SEARCH_BOOKS_URL = `${BASE_URL}/api/book/all-minimized`;
const GET_SEARCH_BOOKS_ERROR_MSG = "Kitaplar alınırken hata meydana geldi.";

// CREATE PASSWORD
const CREATE_PASSWORD_PAGE_ROUTE = "/create-password";
const VALIDATE_EMAIL_TOKEN_URL = `${BASE_URL}/api/user/validate-email-token`;
const RESET_PASSWORD_URL = `${BASE_URL}/api/user/reset-password`;
const VALIDATE_EMAIL_TOKEN_ERROR_MSG = "Email bağlantısı doğrulanamadı.";
const RESET_PASSWORD_SUCCESS_MSG = "Şifreniz başarılı bir şekilde değiştirildi.";
const RESET_PASSWORD_ERROR_MSG = "Şifreniz değiştirilirken hata meydana geldi.";

// FORGOT PASSWORD
const FORGOT_PASSWORD_PAGE_ROUTE = "/forgot-password";
const SEND_LINK_BY_EMAIL_URL = `${BASE_URL}/api/user/send-reset-password-email`;
const SEND_LINK_BY_EMAIL_SUCCESS_MSG =
  "Şifre değiştirme bağlantısı email olarak gönderilmiştir. Lütfen email kutunuzu kontrol ediniz ve işleme devam ediniz.";
const SEND_LINK_BY_EMAIL_ERROR_MSG = "Şifre değiştirme bağlantısı yollanırken bir hata oluştu.";

// HOME
const HOME_ROUTE = "/home";

// ASSIGNMENT
const ASSIGNMENT_LIST_ROUTE = "/assignment";
const DELETE_ASSIGNMENT_URL = `${BASE_URL}/api/book/assignment/delete-assignment-with-user-tasks`;
const DELETE_ASSIGNMENT_ERROR_MSG = "Görev silme işlemi sırasında hata meydana geldi.";
const DELETE_ASSIGNMENT_SUCCESS_MSG = "Görev silme işlemi başarıyla gerçekleşti.";
const GET_ALL_ASSIGNMENTS_URL = `${BASE_URL}/api/book/assignment/all-assignments-created-by`;
const GET_ALL_ASSIGNMENTS_ERROR_MSG = "Görev listesi alınırken hata meydana geldi.";
const GET_ASSIGNMENT_DETAILS_URL = `${BASE_URL}/api/book/assignment/detail`;
const GET_ASSIGNMENT_DETAILS_ERROR_MSG = "Görev detayı alınırken hata meydana geldi.";
const START_ADD_ASSIGNMENT_ROUTE = "/assignment/new";
const GET_ASSIGNMENT_STUDENTS_URL = `${BASE_URL}/api/class-user/get-my-students`;
const GET_ASSIGNMENT_STUDENTS_ERROR_MSG = "Öğrenci listesi getirilirken hata meydana geldi.";
const GET_AUDIO_UPLOAD_PARAMS_URL = `${BASE_URL}/api/upload/get-do-presigned-url`;
const GET_AUDIO_UPLOAD_PARAMS_SUCCESS_MSG = "Upload link'i başarıyla alındı.";
const GET_AUDIO_UPLOAD_PARAMS_ERROR_MSG = "Upload link'i alınırken hata meydana geldi.";
const SAVE_ASSIGNMENT_URL = `${BASE_URL}/api/book/assignment/new-assignment`;
const SAVE_ASSIGNMENT_SUCCESS_MSG =
  "Görev başarılı bir şekilde oluşturuldu. Görevler sayfasına yönlendiriliyorsunuz.";
const SAVE_ASSIGNMENT_ERROR_MSG =
  "Görev oluşturulurken hata meydana geldi. Lütfen tekrar deneyiniz.";
const UPDATE_ASSIGNMENT_URL = `${BASE_URL}/api/book/assignment/update-assignment`;
const UPDATE_ASSIGNMENT_SUCCESS_MSG =
  "Görev başarılı bir şekilde kaydedildi. Görevler sayfasına yönlendiriliyorsunuz.";
const UPDATE_ASSIGNMENT_ERROR_MSG =
  "Görev düzenlenirken hata meydana geldi. Lütfen tekrar deneyiniz.";
const GET_QUIZ_LIST_OF_BOOK_URL = `${BASE_URL}/api/book/quiz/quiz-list-by-book-id`;
const GET_MATERIAL_LIST_OF_BOOK_URL = `${BASE_URL}/api/book/material/material-list-by-book-id`;
const GET_QUIZ_LIST_OF_BOOK_ERROR_MSG =
  "Seçilen kitabın quiz listesi alınırken hata meydana geldi.";
const GET_MATERIAL_LIST_OF_BOOK_ERROR_MSG =
  "Seçilen kitabın materyal listesi alınırken hata meydana geldi.";
const GET_QUIZ_DETAIL_URL = `${BASE_URL}/api/book/quiz/quiz-detail`;
const GET_QUIZ_DETAIL_ERROR_MSG = "Önizlemek için quiz ayrıntısı alınamadı.";

// MESSAGING
const CREATE_NEW_MESSAGE_URL = `${BASE_URL}/api/book/message/new-message`;
const CREATE_NEW_MESSAGE_SUCCESS_MSG = "Mesajınız başarıyla gönderilmiştir.";
const CREATE_NEW_MESSAGE_ERROR_MSG = "Mesaj gönderimi sırasında hata meydana gelmiştir.";
const CREATE_MESSAGE_TO_USERS_URL = `${BASE_URL}/api/book/message/new-message-to-user-list`;
const CREATE_MESSAGE_TO_USERS_SUCCESS_MSG = "Mesajınız başarıyla gönderilmiştir.";
const CREATE_MESSAGE_TO_USERS_ERROR_MSG = "Mesaj gönderimi sırasında hata meydana gelmiştir.";
const MESSAGE_LIST_ROUTE = "/messages";
const GET_MESSAGE_LIST_URL = `${BASE_URL}/api/book/message/get-message-list`;
const GET_MESSAGE_LIST_ERROR_MSG = "Mesajlar getirilirken hata meydana geldi.";

// MY STUDENTS
const GET_MY_STUDENTS_URL = `${BASE_URL}/api/class-user/get-my-students`;
const GET_MY_STUDENTS_ERROR_MSG = "Öğrenci listesi getirilirken hata meydana geldi.";
const UPDATE_MY_STUDENT_URL = `${BASE_URL}/api/class-user/update-my-student-by-id`;
const UPDATE_MY_STUDENT_ERROR_MSG = "Öğrenci güncellenirken hata meydana geldi.";
const UPDATE_MY_STUDENT_SUCCESS_MSG = "Öğrenci başarılı bir şekilde güncellendi.";
const REGENERATE_PASSWORD_URL = `${BASE_URL}/api/user/regenerate-password`;
const REGENERATE_PASSWORD_ERROR_MSG = "Şifre oluşturma işlemi sırasında hata meydana geldi.";
// const REGENERATE_PASSWORD_SUCCESS_MSG = "Öğrenci şifresi başarıyla oluşturuldu.";

// PROFILE
const GET_MY_PROFILE_URL = `${BASE_URL}/api/user/get-profile`;
const GET_MY_PROFILE_ERROR_MSG = "Profil bilgileriniz alınırken hata meydana geldi.";
const UPDATE_MY_PROFILE_URL = `${BASE_URL}/api/user/update-profile`;
const UPDATE_MY_PROFILE_ERROR_MSG = "Profiliniz güncellenirken bir hata meydana geldi.";
const UPDATE_MY_PROFILE_SUCCESS_MSG = "Profiliniz başarıyla güncellendi.";
const CHANGE_PASSWORD_URL = `${BASE_URL}/api/user/update-password`;
const CHANGE_PASSWORD_ERROR_MSG = "Şifre değiştirme işlemi sırasında hata oluştu.";
const CHANGE_PASSWORD_SUCCESS_MSG = "Şifreniz başarıyla değiştirilmiştir.";

// COMMON
const GET_UPLOAD_PARAMS_URL = `${BASE_URL}/api/upload/get-do-presigned-url`;
const GET_UPLOAD_PARAMS_SUCCESS_MSG = "Upload link'i başarıyla alındı.";
const GET_UPLOAD_PARAMS_ERROR_MSG = "Upload link'i alınırken hata meydana geldi.";

// REPORTS
const GET_STUDENT_REPORTS_URL = `${BASE_URL}/api/book/get-student-report`;
const GET_STUDENT_REPORTS_ERROR_MSG = "Öğrenci raporu getirilirken hata meydana geldi.";
const GET_CLASS_STUDENT_REPORTS_URL = `${BASE_URL}/api/book/get-class-report`;
const GET_CLASS_STUDENT_REPORTS_ERROR_MSG = "Sınıf öğrenci raporu getirilirken hata meydana geldi.";
const GET_GENERAL_CLASS_REPORTS_URL = `${BASE_URL}/api/book/get-class-general-report`;
const GET_GENERAL_CLASS_REPORTS_ERROR_MSG = "Sınıf raporu getirilirken hata meydana geldi.";
const GET_SCHOOL_CLASS_REPORTS_URL = `${BASE_URL}/api/book/get-grade-report`;
const GET_SCHOOL_CLASS_REPORTS_ERROR_MSG = "Okul sınıf raporu getirilirken hata meydana geldi.";
const GET_GENERAL_SCHOOL_CLASS_REPORTS_URL = `${BASE_URL}/api/book/get-class-general-report-for-admin`;
const GET_GENERAL_SCHOOL_CLASS_REPORTS_ERROR_MSG = "Sınıf raporu getirilirken hata meydana geldi.";
const GET_SCHOOL_CLASS_STUDENT_REPORTS_URL = `${BASE_URL}/api/book/get-school-class-report`;
const GET_SCHOOL_CLASS_STUDENT_REPORTS_ERROR_MSG =
  "Sınıf öğrenci raporu getirilirken hata meydana geldi.";
const GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS_URL = `${BASE_URL}/api/book/get-sub-organization-general-report-for-admin`;
const GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS_ERROR_MSG =
  "Sınıf raporu getirilirken hata meydana geldi.";

// ACADEMIC CALENDAR
const GET_ALL_ACADEMIC_YEAR_ITEMS_URL = `${BASE_URL}/api/academic-calendar/get-all`;
const GET_ALL_ACADEMIC_YEAR_ITEMS_ERROR_MSG = "Akademik yıl listesi alınırken hata meydana geldi.";
const GET_USER_GRADES_URL = `${BASE_URL}/api/teacher/get-classes`;
const GET_USER_GRADES_ERROR_MSG = "Kullanıcı sınıf bilgileri alınırken hata meydana geldi.";
const GET_ALL_ACADEMIC_YEAR_DETAILS_URL = `${BASE_URL}/api/academic-calendar/get-all-detail`;
const GET_ALL_ACADEMIC_YEAR_DETAILS_ERROR_MSG =
  "Akademik yıl detayları alınırken hata meydana geldi.";

export const pagesMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui, auth, data, localization } = getState();
    // const language = localization?.language ?? "TR";
    const localizations = localization?.pairs;

    switch (action.type) {
      // SIDENAV
      case SELECT_SIDENAV_ITEM:
        next(
          setHeader({
            header: action.payload,
          })
        );
        break;
      // SIGNIN PAGE
      case START_SIGNIN_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === SIGNIN_ROUTE) {
          next(setRedirect({ to: "", feature: SIGNIN_FEATURE }));
        }

        // eslint-disable-next-line no-case-declarations
        const { currentUser } = auth;
        if (currentUser && currentUser.id && currentUser.id > 0) {
          if (ui.origin && ui.origin !== "") {
            next(setRedirect({ to: ui.origin, feature: SIGNIN_FEATURE }));
            next(setOrigin({ origin: "", feature: SIGNIN_FEATURE }));
          } else {
            next(setRedirect({ to: DASHBOARD_ROUTE, feature: SIGNIN_FEATURE }));
          }
        }
        break;
      case SIGNIN:
        next(setLoader({ state: true, feature: SIGNIN_FEATURE }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SIGNIN_URL,
            feature: SIGNIN_FEATURE,
          })
        );
        break;
      case `${SIGNIN_FEATURE} ${API_SUCCESS}`:
        // const userType = action.payload.currentUser?.userType;
        // if (userType === UserTypes.admin) {
        //   window.location.href = EDICT_DASHBOARD_URL;
        // } else if (userType === UserTypes.student || userType === UserTypes.demoStudent) {
        //   window.location.href = MAIN_FRONTEND_URL;
        // }

        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));
        const userType = action.payload.currentUser?.userType;
        if (userType !== UserTypes.other && userType !== UserTypes.demoOther) {
          next(setRedirect({ to: "/error403", feature: SIGNIN_FEATURE }));
          next(removeFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: SIGNIN_FEATURE }));
        } else {
          next(setAuth({ auth: action.payload }));
          next(
            setHeader({
              header: {
                organization: action.payload.currentUser?.organization?.id,
                suborganization: action.payload.currentUser?.suborganizations[0]?.id,
              },
            })
          );
          next(
            setToStorage({
              storageKey: TOKEN_STORAGE_KEY,
              storageValue: action.payload.token,
              feature: SIGNIN_FEATURE,
            })
          );
          next(setRedirect({ to: DASHBOARD_ROUTE, feature: SIGNIN_FEATURE }));
        }
        break;
      case `${SIGNIN_FEATURE} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: error.message,
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else if (action.payload.status === "fail" && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: err,
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else {
          // TODO: Sonraki satır test edilecektir.
          next(
            setNotification({
              type: MessageTypes.error,
              message: action.payload.message,
              feature: SIGNIN_FEATURE,
            })
          );
        }
        next(removeFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: SIGNIN_FEATURE }));
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SIGNIN_FEATURE} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case SIGNOUT:
        next(setLoader({ state: true, feature: NAVBAR }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SIGNOUT_URL,
            feature: NAVBAR,
          })
        );
        break;
      case `${NAVBAR} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: NAVBAR }));
        next(setAuth({ auth: action.payload }));
        next(clearData());
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: NAVBAR,
          })
        );
        next(clearHeader());
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));
        break;
      case `${NAVBAR} ${API_ERROR}`:
        next(setLoader({ state: false, feature: NAVBAR }));
        next(setAuth({ auth: {} }));
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: NAVBAR,
          })
        );
        next(clearHeader());
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${NAVBAR} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case GET_SEARCH_BOOKS:
        if (data?.minimizedBooks?.length > 0) {
          // DO nothing
        } else {
          next(setLoader({ state: true, feature: GET_SEARCH_BOOKS }));
          next(
            apiRequest({
              method: "GET",
              url: GET_SEARCH_BOOKS_URL,
              feature: GET_SEARCH_BOOKS,
            })
          );
        }
        break;
      case `${GET_SEARCH_BOOKS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_SEARCH_BOOKS }));
        next(setData({ data: action.payload, feature: GET_SEARCH_BOOKS }));
        break;
      case `${GET_SEARCH_BOOKS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_SEARCH_BOOKS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_SEARCH_BOOKS_ERROR_MSG,
            feature: GET_SEARCH_BOOKS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_SEARCH_BOOKS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case START_CREATE_PASSWORD_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === CREATE_PASSWORD_PAGE_ROUTE) {
          next(
            setRedirect({
              to: "",
              feature: CREATE_PASSWORD_PAGE,
            })
          );
        }
        break;
      case VALIDATE_EMAIL_TOKEN:
        next(setLoader({ state: true, feature: VALIDATE_EMAIL_TOKEN }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: VALIDATE_EMAIL_TOKEN_URL,
            feature: VALIDATE_EMAIL_TOKEN,
          })
        );
        break;
      case `${VALIDATE_EMAIL_TOKEN} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: VALIDATE_EMAIL_TOKEN }));
        break;
      case `${VALIDATE_EMAIL_TOKEN} ${API_ERROR}`:
        next(setLoader({ state: false, feature: VALIDATE_EMAIL_TOKEN }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: VALIDATE_EMAIL_TOKEN_ERROR_MSG,
            feature: VALIDATE_EMAIL_TOKEN,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${VALIDATE_EMAIL_TOKEN} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case RESET_PASSWORD:
        next(setLoader({ state: true, feature: RESET_PASSWORD }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: RESET_PASSWORD_URL,
            feature: RESET_PASSWORD,
          })
        );
        break;
      case `${RESET_PASSWORD} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: RESET_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: RESET_PASSWORD_SUCCESS_MSG,
            feature: RESET_PASSWORD,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: RESET_PASSWORD }));
        break;
      case `${RESET_PASSWORD} ${API_ERROR}`:
        next(setLoader({ state: false, feature: RESET_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: RESET_PASSWORD_ERROR_MSG,
            feature: RESET_PASSWORD,
          })
        );
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: error.message,
                feature: RESET_PASSWORD,
              })
            );
          });
        } else if (action.payload.status === "fail" && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: err,
                feature: RESET_PASSWORD,
              })
            );
          });
        }
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${RESET_PASSWORD} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case START_FORGOT_PASSWORD_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === FORGOT_PASSWORD_PAGE_ROUTE) {
          next(
            setRedirect({
              to: "",
              feature: FORGOT_PASSWORD_PAGE,
            })
          );
        }
        break;
      case SEND_LINK_BY_EMAIL:
        next(setLoader({ state: true, feature: SEND_LINK_BY_EMAIL }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: SEND_LINK_BY_EMAIL_URL,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        break;
      case `${SEND_LINK_BY_EMAIL} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SEND_LINK_BY_EMAIL }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SEND_LINK_BY_EMAIL_SUCCESS_MSG,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        break;
      case `${SEND_LINK_BY_EMAIL} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SEND_LINK_BY_EMAIL }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: SEND_LINK_BY_EMAIL_ERROR_MSG,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: error.message,
                feature: SEND_LINK_BY_EMAIL,
              })
            );
          });
        } else if (action.payload.status === "fail" && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: err,
                feature: SEND_LINK_BY_EMAIL,
              })
            );
          });
        }
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SEND_LINK_BY_EMAIL} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;

      case START_ADD_OR_EDIT_ASSIGNMENT:
        if (ui && ui.redirectTo && ui.redirectTo === START_ADD_ASSIGNMENT_ROUTE) {
          next(
            setRedirect({
              to: "",
              feature: START_ADD_OR_EDIT_ASSIGNMENT,
            })
          );
        }
        if (ui && ui.redirectTo && ui.redirectTo === "/assignment/edit") {
          next(
            setRedirect({
              to: "",
              feature: START_ADD_OR_EDIT_ASSIGNMENT,
            })
          );
        }
        if (data && data.minimizedBooks && data.minimizedBooks.length > 0) {
          // DO NOTHING
        } else {
          next(setLoader({ state: true, feature: START_ADD_OR_EDIT_ASSIGNMENT }));
          next(
            apiRequest({
              method: "GET",
              url: GET_SEARCH_BOOKS_URL,
              feature: START_ADD_OR_EDIT_ASSIGNMENT,
            })
          );
        }
        break;
      case `${START_ADD_OR_EDIT_ASSIGNMENT} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: START_ADD_OR_EDIT_ASSIGNMENT }));
        next(setData({ data: action.payload, feature: START_ADD_OR_EDIT_ASSIGNMENT }));
        break;
      case `${START_ADD_OR_EDIT_ASSIGNMENT} ${API_ERROR}`:
        next(setLoader({ state: false, feature: START_ADD_OR_EDIT_ASSIGNMENT }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: GET_SEARCH_BOOKS_ERROR_MSG,
            feature: START_ADD_OR_EDIT_ASSIGNMENT,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${START_ADD_OR_EDIT_ASSIGNMENT} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;

      case GET_ASSIGNMENT_STUDENTS:
        next(setLoader({ state: true, feature: GET_ASSIGNMENT_STUDENTS }));
        next(
          apiRequest({
            method: "GET",
            url: GET_ASSIGNMENT_STUDENTS_URL,
            feature: GET_ASSIGNMENT_STUDENTS,
          })
        );
        break;
      case `${GET_ASSIGNMENT_STUDENTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_ASSIGNMENT_STUDENTS }));
        next(setData({ data: action.payload, feature: GET_ASSIGNMENT_STUDENTS }));
        break;
      case `${GET_ASSIGNMENT_STUDENTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_ASSIGNMENT_STUDENTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ASSIGNMENT_STUDENTS_ERROR_MSG ?? GET_ASSIGNMENT_STUDENTS_ERROR_MSG,
            feature: GET_ASSIGNMENT_STUDENTS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_ASSIGNMENT_STUDENTS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_AUDIO_UPLOAD_PARAMS:
        next(setLoader({ state: true, feature: GET_AUDIO_UPLOAD_PARAMS }));
        next(
          apiRequest({
            method: "POST",
            body: action.payload,
            url: GET_AUDIO_UPLOAD_PARAMS_URL,
            feature: GET_AUDIO_UPLOAD_PARAMS,
          })
        );
        break;
      case `${GET_AUDIO_UPLOAD_PARAMS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_AUDIO_UPLOAD_PARAMS }));
        next(setData({ data: action.payload, feature: GET_AUDIO_UPLOAD_PARAMS }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: GET_AUDIO_UPLOAD_PARAMS_SUCCESS_MSG,
            feature: GET_AUDIO_UPLOAD_PARAMS,
          })
        );
        break;
      case `${GET_AUDIO_UPLOAD_PARAMS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_AUDIO_UPLOAD_PARAMS }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_AUDIO_UPLOAD_PARAMS_ERROR_MSG,
            feature: GET_AUDIO_UPLOAD_PARAMS,
          })
        );
        break;
      case REMOVE_AUDIO_UPLOAD_PARAMS:
        const where = "uploadParameters";
        next(
          removeData({
            feature: REMOVE_AUDIO_UPLOAD_PARAMS,
            where,
          })
        );
        break;
      case SAVE_ASSIGNMENT:
        next(setLoader({ state: true, feature: SAVE_ASSIGNMENT }));
        next(
          apiRequest({
            method: "POST",
            body: action.payload,
            url: SAVE_ASSIGNMENT_URL,
            feature: SAVE_ASSIGNMENT,
          })
        );
        break;
      case `${SAVE_ASSIGNMENT} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SAVE_ASSIGNMENT }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_ASSIGNMENT_SUCCESS_MSG,
            feature: SAVE_ASSIGNMENT,
          })
        );

        if (data.assignments) {
          // Eğer assignments varsa ekle yoksa zaten görevlere yönlenince assignment list alınacak!
          const copyOfAssignmentsForNewAssignment = _.cloneDeepWith(data.assignments);
          _.set(
            copyOfAssignmentsForNewAssignment,
            [action.payload.assignment?.id],
            action.payload.assignment
          );

          next(
            setData({
              data: {
                assignments: copyOfAssignmentsForNewAssignment,
              },
              feature: SAVE_ASSIGNMENT,
            })
          );
        }

        if (data.messages) {
          next(
            setData({
              data: { messages: [action.payload.message, ...(data.messages ?? [])] },
              feature: SAVE_ASSIGNMENT,
            })
          );
        }

        next(setRedirect({ to: "/assignment", feature: SAVE_ASSIGNMENT }));
        break;
      case `${SAVE_ASSIGNMENT} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SAVE_ASSIGNMENT }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: localizations?.SAVE_ASSIGNMENT_ERROR_MSG ?? SAVE_ASSIGNMENT_ERROR_MSG,
            feature: SAVE_ASSIGNMENT,
          })
        );
        next(
          sendLog({
            product: SAVE_ASSIGNMENT,
            title: `${SAVE_ASSIGNMENT} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case UPDATE_ASSIGNMENT:
        // eslint-disable-next-line no-console
        // console.log("UPDATE Assignment:", JSON.stringify(action.payload));
        next(setLoader({ state: true, feature: UPDATE_ASSIGNMENT }));
        next(
          apiRequest({
            method: "POST",
            body: action.payload,
            url: UPDATE_ASSIGNMENT_URL,
            feature: UPDATE_ASSIGNMENT,
          })
        );
        break;
      case `${UPDATE_ASSIGNMENT} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: UPDATE_ASSIGNMENT }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: UPDATE_ASSIGNMENT_SUCCESS_MSG,
            feature: UPDATE_ASSIGNMENT,
          })
        );

        if (data.assignments) {
          // Eğer assignments varsa ekle yoksa zaten görevlere yönlenince assignment list alınacak!
          const copyOfAssignmentsForUpdateAssignment = _.cloneDeepWith(data.assignments);
          _.set(
            copyOfAssignmentsForUpdateAssignment,
            [action.payload.assignment?.id],
            action.payload.assignment
          );
          next(
            setData({
              data: {
                assignments: copyOfAssignmentsForUpdateAssignment,
              },
              feature: UPDATE_ASSIGNMENT,
            })
          );
        }

        next(setRedirect({ to: "/assignment", feature: UPDATE_ASSIGNMENT }));
        break;

      case `${UPDATE_ASSIGNMENT} ${API_ERROR}`:
        next(setLoader({ state: false, feature: UPDATE_ASSIGNMENT }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.UPDATE_ASSIGNMENT_ERROR_MSG ?? UPDATE_ASSIGNMENT_ERROR_MSG,
            feature: UPDATE_ASSIGNMENT,
          })
        );
        next(
          sendLog({
            product: UPDATE_ASSIGNMENT,
            title: `${UPDATE_ASSIGNMENT} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );

        break;
      case START_ASSIGNMENT_LIST:
        if (ui && ui.redirectTo && ui.redirectTo === ASSIGNMENT_LIST_ROUTE) {
          next(
            setRedirect({
              to: "",
              feature: START_ASSIGNMENT_LIST,
            })
          );
        }

        // Get All Assignments
        next(setLoader({ state: true, feature: START_ASSIGNMENT_LIST }));
        next(
          apiRequest({
            method: "GET",
            url: GET_ALL_ASSIGNMENTS_URL,
            feature: START_ASSIGNMENT_LIST,
          })
        );

        break;
      case `${START_ASSIGNMENT_LIST} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: START_ASSIGNMENT_LIST }));
        next(
          setData({
            data: { assignments: action.payload?.allAssignments },
            feature: START_ASSIGNMENT_LIST,
          })
        );
        break;
      case `${START_ASSIGNMENT_LIST} ${API_ERROR}`:
        next(setLoader({ state: false, feature: START_ASSIGNMENT_LIST }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ALL_ASSIGNMENTS_ERROR_MSG ?? GET_ALL_ASSIGNMENTS_ERROR_MSG,
            feature: START_ASSIGNMENT_LIST,
          })
        );
        break;
      case GET_ASSIGNMENT_DETAILS:
        next(
          setLoader({
            state: true,
            feature: GET_ASSIGNMENT_DETAILS,
            identifier: GET_ASSIGNMENT_DETAILS,
          })
        );
        next(
          apiRequest({
            method: "GET",
            url: `${GET_ASSIGNMENT_DETAILS_URL}/${action.payload.assignmentId}`,
            feature: GET_ASSIGNMENT_DETAILS,
          })
        );
        break;
      case `${GET_ASSIGNMENT_DETAILS} ${API_SUCCESS}`:
        next(
          setLoader({
            state: false,
            feature: GET_ASSIGNMENT_DETAILS,
            identifier: GET_ASSIGNMENT_DETAILS,
          })
        );

        const { assignmentId, users } = action.payload;
        next(
          appendDataToObjectItem({
            feature: GET_ASSIGNMENT_DETAILS,
            where: "assignments",
            item: assignmentId,
            data: { users },
          })
        );
        break;
      case `${GET_ASSIGNMENT_DETAILS} ${API_ERROR}`:
        next(
          setLoader({
            state: false,
            feature: GET_ASSIGNMENT_DETAILS,
            identifier: GET_ASSIGNMENT_DETAILS,
          })
        );
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ASSIGNMENT_DETAILS_ERROR_MSG ?? GET_ASSIGNMENT_DETAILS_ERROR_MSG,
            feature: GET_ASSIGNMENT_DETAILS,
          })
        );
        break;

      case GET_QUIZ_LIST_OF_BOOK:
        next(
          apiRequest({
            method: "GET",
            url: `${GET_QUIZ_LIST_OF_BOOK_URL}/${action.payload.bookId}`,
            feature: GET_QUIZ_LIST_OF_BOOK,
          })
        );
        break;
      case `${GET_QUIZ_LIST_OF_BOOK} ${API_SUCCESS}`:
        next(
          setData({
            data: { bookQuizList: action.payload },
            feature: GET_QUIZ_LIST_OF_BOOK,
          })
        );
        break;
      case `${GET_QUIZ_LIST_OF_BOOK} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_QUIZ_LIST_OF_BOOK_ERROR_MSG,
            feature: GET_QUIZ_LIST_OF_BOOK,
          })
        );
        break;
      case GET_MATERIAL_LIST_OF_BOOK:
        next(
          apiRequest({
            method: "GET",
            url: `${GET_MATERIAL_LIST_OF_BOOK_URL}/${action.payload.bookId}`,
            feature: GET_MATERIAL_LIST_OF_BOOK,
          })
        );
        break;
      case `${GET_MATERIAL_LIST_OF_BOOK} ${API_SUCCESS}`:
        next(
          setData({
            data: { bookMaterialList: action.payload },
            feature: GET_MATERIAL_LIST_OF_BOOK,
          })
        );
        break;
      case `${GET_MATERIAL_LIST_OF_BOOK} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_MATERIAL_LIST_OF_BOOK_ERROR_MSG,
            feature: GET_MATERIAL_LIST_OF_BOOK,
          })
        );
        break;
      case GET_QUIZ_DETAIL:
        if (
          action.payload.quizId === null ||
          action.payload.quizId === undefined ||
          action.payload.quizId === 0
        ) {
          next(
            setData({
              data: { previewQuiz: undefined },
              feature: GET_QUIZ_DETAIL,
            })
          );
        } else {
          next(
            apiRequest({
              method: "GET",
              url: `${GET_QUIZ_DETAIL_URL}/${action.payload.quizId}`,
              feature: GET_QUIZ_DETAIL,
            })
          );
        }

        break;
      case `${GET_QUIZ_DETAIL} ${API_SUCCESS}`:
        next(
          setData({
            data: { previewQuiz: action.payload?.quiz },
            feature: GET_QUIZ_DETAIL,
          })
        );
        break;
      case `${GET_QUIZ_DETAIL} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_QUIZ_DETAIL_ERROR_MSG,
            feature: GET_QUIZ_DETAIL,
          })
        );
        break;

      case CREATE_NEW_MESSAGE:
        next(
          setLoader({
            state: true,
            feature: CREATE_NEW_MESSAGE,
          })
        );
        next(
          apiRequest({
            method: "POST",
            body: action.payload,
            url: CREATE_NEW_MESSAGE_URL,
            feature: CREATE_NEW_MESSAGE,
          })
        );
        break;
      case `${CREATE_NEW_MESSAGE} ${API_SUCCESS}`:
        next(
          setLoader({
            state: false,
            feature: CREATE_NEW_MESSAGE,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message: CREATE_NEW_MESSAGE_SUCCESS_MSG,
            feature: CREATE_NEW_MESSAGE,
          })
        );

        // state operations
        const copyOfAssignmentsForCreateNewMessage = _.cloneDeepWith(data.assignments);
        const { message: newMessage } = action.payload;

        data.assignments[newMessage?.assignmentId]?.users?.forEach((user, userIndex) => {
          if (user.id === newMessage?.users[0]) {
            const lengthOfMessages = user.messages?.length ?? 0;

            _.set(
              copyOfAssignmentsForCreateNewMessage,
              [newMessage?.assignmentId, "users", userIndex, "messages", lengthOfMessages],
              {
                id: newMessage.groupUuid,
                createdAt: newMessage.creationDate,
                content: newMessage.message,
                audioMessageUrl: newMessage.audioMessageUrl,
                isFeedback: newMessage.isFeedback,
              }
            );
          }
        });

        next(
          setData({
            data: {
              assignments: copyOfAssignmentsForCreateNewMessage,
            },
            feature: CREATE_NEW_MESSAGE,
          })
        );
        break;
      case `${CREATE_NEW_MESSAGE} ${API_ERROR}`:
        next(
          setLoader({
            state: false,
            feature: CREATE_NEW_MESSAGE,
          })
        );
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.CREATE_NEW_MESSAGE_ERROR_MSG ?? CREATE_NEW_MESSAGE_ERROR_MSG,
            feature: CREATE_NEW_MESSAGE,
          })
        );
        break;
      case SAVE_UNSAVED_ASSIGNMENT:
        next(
          setData({
            data: action.payload,
            feature: SAVE_UNSAVED_ASSIGNMENT,
          })
        );
        break;
      case START_HOME_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === HOME_ROUTE) {
          next(
            setRedirect({
              to: "",
              feature: START_HOME_PAGE,
            })
          );
        }

        // Get All Assignments
        next(setLoader({ state: true, feature: START_HOME_PAGE }));
        next(
          apiRequest({
            method: "GET",
            url: GET_ALL_ASSIGNMENTS_URL,
            feature: START_HOME_PAGE,
          })
        );

        break;
      case `${START_HOME_PAGE} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: START_HOME_PAGE }));
        next(
          setData({
            data: { assignments: action.payload?.allAssignments },
            feature: START_HOME_PAGE,
          })
        );
        break;
      case `${START_HOME_PAGE} ${API_ERROR}`:
        next(setLoader({ state: false, feature: START_HOME_PAGE }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ALL_ASSIGNMENTS_ERROR_MSG ?? GET_ALL_ASSIGNMENTS_ERROR_MSG,
            feature: START_HOME_PAGE,
          })
        );
        break;

      case GET_HOME_ASSIGNMENT_DETAILS:
        next(
          setLoader({
            state: true,
            feature: GET_HOME_ASSIGNMENT_DETAILS,
            identifier: GET_HOME_ASSIGNMENT_DETAILS,
          })
        );
        next(
          apiRequest({
            method: "GET",
            url: `${GET_ASSIGNMENT_DETAILS_URL}/${action.payload.assignmentId}`,
            feature: GET_HOME_ASSIGNMENT_DETAILS,
          })
        );
        break;
      case `${GET_HOME_ASSIGNMENT_DETAILS} ${API_SUCCESS}`:
        next(
          setLoader({
            state: false,
            feature: GET_HOME_ASSIGNMENT_DETAILS,
            identifier: GET_HOME_ASSIGNMENT_DETAILS,
          })
        );

        next(
          appendDataToObjectItem({
            feature: GET_HOME_ASSIGNMENT_DETAILS,
            where: "assignments",
            item: action.payload.assignmentId,
            data: { users: action.payload.users },
          })
        );
        break;
      case `${GET_HOME_ASSIGNMENT_DETAILS} ${API_ERROR}`:
        next(
          setLoader({
            state: false,
            feature: GET_HOME_ASSIGNMENT_DETAILS,
            identifier: GET_HOME_ASSIGNMENT_DETAILS,
          })
        );
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ASSIGNMENT_DETAILS_ERROR_MSG ?? GET_ASSIGNMENT_DETAILS_ERROR_MSG,
            feature: GET_HOME_ASSIGNMENT_DETAILS,
          })
        );
        break;
      case GET_ALL_BOOKS_WITH_FILTER_LOOKUPS:
        next(setLoader({ state: true, feature: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS }));
        let missingAllBooksWithFilterLookupKeys = [];
        const requiredAllBooksWithFilterLookupKeys = [
          GRADES,
          LEVELS,
          COURSES,
          SKILLS,
          UNITS,
          SPECIAL_DAYS,
          OBJECTIVES,
        ];

        if (!data.lookups || Object.keys(data.lookups).length === 0) {
          missingAllBooksWithFilterLookupKeys = requiredAllBooksWithFilterLookupKeys;
        } else {
          requiredAllBooksWithFilterLookupKeys.forEach((k) => {
            if (data.lookups[k] && Object.keys(data.lookups[k]).length > 0) {
              // Do nothing
            } else {
              missingAllBooksWithFilterLookupKeys.push(k);
            }
          });
        }

        if (missingAllBooksWithFilterLookupKeys.length > 0) {
          next(
            apiRequest({
              body: {
                lookupKeys: missingAllBooksWithFilterLookupKeys,
              },
              method: "POST",
              url: GET_LOOKUPS_DETAILED_URL,
              feature: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS,
            })
          );
        }
        break;
      case `${GET_ALL_BOOKS_WITH_FILTER_LOOKUPS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS }));
        next(
          setData({
            data: {
              lookups: {
                ...data.lookups,
                ...action.payload.lookups,
              },
            },
            feature: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS,
          })
        );
        break;
      case `${GET_ALL_BOOKS_WITH_FILTER_LOOKUPS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_LOOKUPS_DETAILED_API_ERROR_MSG ??
              GET_LOOKUPS_DETAILED_API_ERROR_MSG,
            feature: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_ALL_BOOKS_WITH_FILTER_LOOKUPS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_ALL_BOOKS_WITH_FILTER:
        next(setLoader({ state: true, feature: GET_ALL_BOOKS_WITH_FILTER }));
        next(
          apiRequest({
            body: action.payload,
            method: "POST",
            url: GET_ALL_BOOKS_WITH_FILTER_URL,
            feature: GET_ALL_BOOKS_WITH_FILTER,
          })
        );
        break;
      case `${GET_ALL_BOOKS_WITH_FILTER} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_ALL_BOOKS_WITH_FILTER }));
        next(
          setData({
            data: action.payload,
            feature: GET_ALL_BOOKS_WITH_FILTER,
          })
        );
        break;
      case `${GET_ALL_BOOKS_WITH_FILTER} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_ALL_BOOKS_WITH_FILTER }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ALL_BOOKS_WITH_FILTER_ERROR_MSG ??
              GET_ALL_BOOKS_WITH_FILTER_ERROR_MSG,
            feature: GET_ALL_BOOKS_WITH_FILTER,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_ALL_BOOKS_WITH_FILTER} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_MY_CLASSES:
        next(
          apiRequest({
            method: "GET",
            url: GET_MY_CLASSES_URL,
            feature: GET_MY_CLASSES,
          })
        );
        break;
      case `${GET_MY_CLASSES} ${API_SUCCESS}`:
        next(
          setData({
            data: action.payload,
            feature: GET_MY_CLASSES,
          })
        );
        break;
      case `${GET_MY_CLASSES} ${API_ERROR}`:
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_MY_CLASSES} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_MY_STUDENTS:
        next(setLoader({ state: true, feature: GET_MY_STUDENTS }));
        next(
          apiRequest({
            method: "GET",
            url: GET_MY_STUDENTS_URL,
            feature: GET_MY_STUDENTS,
          })
        );

        break;
      case `${GET_MY_STUDENTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_MY_STUDENTS }));
        next(setData({ data: action.payload, feature: GET_MY_STUDENTS }));
        break;
      case `${GET_MY_STUDENTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_MY_STUDENTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: localizations?.GET_MY_STUDENTS_ERROR_MSG ?? GET_MY_STUDENTS_ERROR_MSG,
            feature: GET_MY_STUDENTS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_MY_STUDENTS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_MY_STUDENTS_LOOKUPS:
        next(setLoader({ state: true, feature: GET_ALL_BOOKS_WITH_FILTER_LOOKUPS }));
        let missingGetMyStudentsLookupKeys = [];
        const requiredGetMyStudentsLookupKeys = [LEVELS];

        if (!data.lookups || Object.keys(data.lookups).length === 0) {
          missingGetMyStudentsLookupKeys = requiredGetMyStudentsLookupKeys;
        } else {
          requiredGetMyStudentsLookupKeys.forEach((k) => {
            if (data.lookups[k] && Object.keys(data.lookups[k]).length > 0) {
              // Do nothing
            } else {
              missingGetMyStudentsLookupKeys.push(k);
            }
          });
        }

        if (missingGetMyStudentsLookupKeys.length > 0) {
          next(
            apiRequest({
              body: {
                lookupKeys: missingGetMyStudentsLookupKeys,
              },
              method: "POST",
              url: GET_LOOKUPS_DETAILED_URL,
              feature: GET_MY_STUDENTS_LOOKUPS,
            })
          );
        }

        break;

      case `${GET_MY_STUDENTS_LOOKUPS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_MY_STUDENTS_LOOKUPS }));
        next(
          setData({
            data: {
              lookups: {
                ...data.lookups,
                ...action.payload.lookups,
              },
            },
            feature: GET_MY_STUDENTS_LOOKUPS,
          })
        );
        break;
      case `${GET_MY_STUDENTS_LOOKUPS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_MY_STUDENTS_LOOKUPS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_LOOKUPS_DETAILED_API_ERROR_MSG ??
              GET_LOOKUPS_DETAILED_API_ERROR_MSG,
            feature: GET_MY_STUDENTS_LOOKUPS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_MY_STUDENTS_LOOKUPS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case UPDATE_MY_STUDENT:
        next(
          apiRequest({
            body: {
              studentUserId: action.payload.studentId,
              updatedFields: action.payload.updatedFields,
            },
            method: "POST",
            url: UPDATE_MY_STUDENT_URL,
            feature: UPDATE_MY_STUDENT,
          })
        );
        break;
      case `${UPDATE_MY_STUDENT} ${API_SUCCESS}`:
        const updatedStudents = Array.from(data.students, (s) => {
          if (s.id !== action.payload.student.id) {
            return s;
          }

          return action.payload.student;
        });
        next(
          setData({
            data: {
              students: updatedStudents,
            },
            feature: UPDATE_MY_STUDENT,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message: UPDATE_MY_STUDENT_SUCCESS_MSG,
            feature: UPDATE_MY_STUDENT,
          })
        );
        break;
      case `${UPDATE_MY_STUDENT} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message: UPDATE_MY_STUDENT_ERROR_MSG,
            feature: UPDATE_MY_STUDENT,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${UPDATE_MY_STUDENT} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case REGENERATE_PASSWORD:
        next(setLoader({ state: true, feature: REGENERATE_PASSWORD }));
        if (action.payload.studentId > 0) {
          next(
            apiRequest({
              method: "GET",
              url: `${REGENERATE_PASSWORD_URL}/${action.payload.studentId}`,
              feature: REGENERATE_PASSWORD,
            })
          );
        }
        break;
      case `${REGENERATE_PASSWORD} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: REGENERATE_PASSWORD }));
        next(
          setData({
            data: {
              passwordGenerationResult: action.payload,
            },
            feature: REGENERATE_PASSWORD,
          })
        );

        const copyOfStudentsForRegeneratePassword = _.cloneDeepWith(data.students);
        const studentIndex = data.students.findIndex(
          (student) => student.id === action.payload.studentId
        );
        _.set(
          copyOfStudentsForRegeneratePassword,
          [studentIndex, "generatedPassword"],
          action.payload.generatedPassword
        );
        next(
          setData({
            data: {
              students: copyOfStudentsForRegeneratePassword,
            },
            feature: REGENERATE_PASSWORD,
          })
        );
        break;
      case `${REGENERATE_PASSWORD} ${API_ERROR}`:
        next(setLoader({ state: false, feature: REGENERATE_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: REGENERATE_PASSWORD_ERROR_MSG,
            feature: REGENERATE_PASSWORD,
          })
        );
        break;
      case REMOVE_PASSWORD_GENERATION_RESULT:
        next(
          removeData({
            feature: REMOVE_PASSWORD_GENERATION_RESULT,
            where: "passwordGenerationResult",
          })
        );
        break;
      case GET_MY_PROFILE:
        next(setLoader({ state: true, feature: GET_MY_PROFILE }));
        next(
          apiRequest({
            method: "GET",
            url: GET_MY_PROFILE_URL,
            feature: GET_MY_PROFILE,
          })
        );
        break;
      case `${GET_MY_PROFILE} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_MY_PROFILE }));
        next(
          setData({
            data: {
              profile: action.payload.userProfile,
            },
            feature: GET_MY_PROFILE,
          })
        );
        break;
      case `${GET_MY_PROFILE} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_MY_PROFILE }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: localizations?.GET_MY_PROFILE_ERROR_MSG ?? GET_MY_PROFILE_ERROR_MSG,
            feature: GET_MY_PROFILE,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_MY_PROFILE} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case UPDATE_MY_PROFILE:
        next(setLoader({ state: true, feature: UPDATE_MY_PROFILE }));
        next(
          apiRequest({
            method: "POST",
            body: action.payload,
            url: UPDATE_MY_PROFILE_URL,
            feature: UPDATE_MY_PROFILE,
          })
        );
        break;
      case `${UPDATE_MY_PROFILE} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: UPDATE_MY_PROFILE }));
        next(
          setData({
            data: {
              profile: action.payload.userProfile,
            },
            feature: UPDATE_MY_PROFILE,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message: UPDATE_MY_PROFILE_SUCCESS_MSG,
            feature: UPDATE_MY_PROFILE,
          })
        );
        break;

      case `${UPDATE_MY_PROFILE} ${API_ERROR}`:
        next(setLoader({ state: false, feature: UPDATE_MY_PROFILE }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: UPDATE_MY_PROFILE_ERROR_MSG,
            feature: UPDATE_MY_PROFILE,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${UPDATE_MY_PROFILE} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_UPLOAD_PARAMS:
        next(setLoader({ state: true, feature: GET_UPLOAD_PARAMS }));
        next(
          apiRequest({
            method: "POST",
            body: action.payload,
            url: GET_UPLOAD_PARAMS_URL,
            feature: GET_UPLOAD_PARAMS,
          })
        );
        break;
      case `${GET_UPLOAD_PARAMS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_UPLOAD_PARAMS }));
        next(
          setData({
            data: {
              uploadParameters: {
                [action.payload.uploadParameters.identifier]: action.payload.uploadParameters,
              },
            },
            feature: GET_UPLOAD_PARAMS,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message: GET_UPLOAD_PARAMS_SUCCESS_MSG,
            feature: GET_UPLOAD_PARAMS,
          })
        );
        break;
      case `${GET_UPLOAD_PARAMS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_UPLOAD_PARAMS }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_UPLOAD_PARAMS_ERROR_MSG,
            feature: GET_UPLOAD_PARAMS,
          })
        );
        break;
      case REMOVE_UPLOAD_PARAMS:
        const copyOfUploadParameters = { ...data.uploadParameters };
        delete copyOfUploadParameters[action.payload.identifier];
        next(
          setData({
            data: {
              uploadParameters: copyOfUploadParameters,
            },
            feature: REMOVE_UPLOAD_PARAMS,
          })
        );
        break;
      case CHANGE_PASSWORD:
        next(setLoader({ state: true, feature: CHANGE_PASSWORD }));
        next(
          apiRequest({
            method: "POST",
            body: { oldPassword: action.payload.oldPassword, password: action.payload.newPassword },
            url: CHANGE_PASSWORD_URL,
            feature: CHANGE_PASSWORD,
          })
        );
        break;
      case `${CHANGE_PASSWORD} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: CHANGE_PASSWORD }));
        next(setAuth({ auth: action.payload }));
        next(
          setToStorage({
            storageKey: TOKEN_STORAGE_KEY,
            storageValue: action.payload.token,
            feature: CHANGE_PASSWORD,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message: CHANGE_PASSWORD_SUCCESS_MSG,
            feature: CHANGE_PASSWORD,
          })
        );
        break;
      case `${CHANGE_PASSWORD} ${API_ERROR}`:
        next(setLoader({ state: false, feature: CHANGE_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: CHANGE_PASSWORD_ERROR_MSG,
            feature: CHANGE_PASSWORD,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${CHANGE_PASSWORD} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case CREATE_MESSAGE_TO_USERS:
        next(setLoader({ state: true, feature: CREATE_MESSAGE_TO_USERS }));
        next(
          apiRequest({
            method: "POST",
            body: { message: action.payload },
            url: CREATE_MESSAGE_TO_USERS_URL,
            feature: CREATE_MESSAGE_TO_USERS,
          })
        );
        break;
      case `${CREATE_MESSAGE_TO_USERS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: CREATE_MESSAGE_TO_USERS }));
        if (data.messages) {
          next(
            setData({
              data: { messages: [action.payload.message, ...(data.messages ?? [])] },
              feature: CREATE_MESSAGE_TO_USERS,
            })
          );
        }
        next(
          setNotification({
            type: MessageTypes.success,
            message: CREATE_MESSAGE_TO_USERS_SUCCESS_MSG,
            feature: CREATE_MESSAGE_TO_USERS,
          })
        );
        next(setRedirect({ to: "/messages", feature: CREATE_MESSAGE_TO_USERS }));
        break;
      case `${CREATE_MESSAGE_TO_USERS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: CREATE_MESSAGE_TO_USERS }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: CREATE_MESSAGE_TO_USERS_ERROR_MSG,
            feature: CREATE_MESSAGE_TO_USERS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${CREATE_MESSAGE_TO_USERS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_MESSAGE_LIST:
        if (ui && ui.redirectTo && ui.redirectTo === MESSAGE_LIST_ROUTE) {
          next(setRedirect({ to: "", feature: GET_MESSAGE_LIST }));
        }

        next(setLoader({ state: true, feature: GET_MESSAGE_LIST }));
        next(
          apiRequest({
            method: "GET",
            url: GET_MESSAGE_LIST_URL,
            feature: GET_MESSAGE_LIST,
          })
        );

        break;
      case `${GET_MESSAGE_LIST} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_MESSAGE_LIST }));
        next(setData({ data: action.payload, feature: GET_MESSAGE_LIST }));
        break;
      case `${GET_MESSAGE_LIST} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_MESSAGE_LIST }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: localizations?.GET_MESSAGE_LIST_ERROR_MSG ?? GET_MESSAGE_LIST_ERROR_MSG,
            feature: GET_MESSAGE_LIST,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_MESSAGE_LIST} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case GET_TABS_LOOKUPS:
        if (
          !data.lookups ||
          Object.keys(data.lookups).length === 0 ||
          !data.lookups[COURSES] ||
          Object.keys(data.lookups[COURSES]).length === 0
        ) {
          next(setLoader({ state: true, feature: GET_TABS_LOOKUPS }));
          next(
            apiRequest({
              body: {
                lookupKeys: [COURSES],
              },
              method: "POST",
              url: GET_LOOKUPS_DETAILED_URL,
              feature: GET_TABS_LOOKUPS,
            })
          );
        }
        break;
      case `${GET_TABS_LOOKUPS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_TABS_LOOKUPS }));
        next(
          setData({
            data: {
              lookups: {
                ...data.lookups,
                ...action.payload.lookups,
              },
            },
            feature: GET_TABS_LOOKUPS,
          })
        );
        break;
      case `${GET_TABS_LOOKUPS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_TABS_LOOKUPS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_LOOKUPS_DETAILED_API_ERROR_MSG ??
              GET_LOOKUPS_DETAILED_API_ERROR_MSG,
            feature: GET_TABS_LOOKUPS,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_TABS_LOOKUPS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case START_CALENDAR:
        // Get All Assignments
        next(setLoader({ state: true, feature: START_CALENDAR }));
        next(
          apiRequest({
            method: "GET",
            url: GET_ALL_ASSIGNMENTS_URL,
            feature: START_CALENDAR,
          })
        );

        break;
      case `${START_CALENDAR} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: START_CALENDAR }));
        next(
          setData({
            data: { assignments: action.payload?.allAssignments },
            feature: START_CALENDAR,
          })
        );
        break;
      case `${START_CALENDAR} ${API_ERROR}`:
        next(setLoader({ state: false, feature: START_CALENDAR }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ALL_ASSIGNMENTS_ERROR_MSG ?? GET_ALL_ASSIGNMENTS_ERROR_MSG,
            feature: START_CALENDAR,
          })
        );
        break;
      case GET_STUDENT_REPORTS:
        if (action.payload.studentId === data.studentReports?.id) {
          // Do nothing
        } else {
          next(setLoader({ state: true, feature: GET_STUDENT_REPORTS }));
          next(
            apiRequest({
              method: "POST",
              url: GET_STUDENT_REPORTS_URL,
              feature: GET_STUDENT_REPORTS,
              body: action.payload,
            })
          );
        }
        break;
      case `${GET_STUDENT_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_STUDENT_REPORTS }));
        next(
          setData({
            data: { studentReports: action.payload?.userReport },
            feature: GET_STUDENT_REPORTS,
          })
        );
        break;
      case `${GET_STUDENT_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_STUDENT_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_STUDENT_REPORTS_ERROR_MSG ?? GET_STUDENT_REPORTS_ERROR_MSG,
            feature: GET_STUDENT_REPORTS,
          })
        );
        break;
      case GET_CLASS_STUDENT_REPORTS:
        next(setLoader({ state: true, feature: GET_CLASS_STUDENT_REPORTS }));
        next(
          apiRequest({
            method: "POST",
            url: GET_CLASS_STUDENT_REPORTS_URL,
            feature: GET_CLASS_STUDENT_REPORTS,
            body: action.payload,
          })
        );
        break;
      case `${GET_CLASS_STUDENT_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_CLASS_STUDENT_REPORTS }));
        next(
          setData({
            data: { classReports: action.payload },
            feature: GET_CLASS_STUDENT_REPORTS,
          })
        );
        break;
      case `${GET_CLASS_STUDENT_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_CLASS_STUDENT_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_CLASS_STUDENT_REPORTS_ERROR_MSG ??
              GET_CLASS_STUDENT_REPORTS_ERROR_MSG,
            feature: GET_CLASS_STUDENT_REPORTS,
          })
        );
        break;
      case GET_GENERAL_CLASS_REPORTS:
        next(setLoader({ state: true, feature: GET_GENERAL_CLASS_REPORTS }));
        next(
          apiRequest({
            method: "POST",
            url: GET_GENERAL_CLASS_REPORTS_URL,
            feature: GET_GENERAL_CLASS_REPORTS,
            body: action.payload,
          })
        );
        break;
      case `${GET_GENERAL_CLASS_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_GENERAL_CLASS_REPORTS }));
        next(
          setData({
            data: { generalClassReports: action.payload?.classesReport },
            feature: GET_GENERAL_CLASS_REPORTS,
          })
        );
        break;
      case `${GET_GENERAL_CLASS_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_GENERAL_CLASS_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_GENERAL_CLASS_REPORTS_ERROR_MSG ??
              GET_GENERAL_CLASS_REPORTS_ERROR_MSG,
            feature: GET_GENERAL_CLASS_REPORTS,
          })
        );
        break;
      case GET_SCHOOL_CLASS_REPORTS:
        next(setLoader({ state: true, feature: GET_SCHOOL_CLASS_REPORTS }));
        next(
          apiRequest({
            method: "POST",
            url: GET_SCHOOL_CLASS_REPORTS_URL,
            feature: GET_SCHOOL_CLASS_REPORTS,
            body: action.payload,
          })
        );
        break;
      case `${GET_SCHOOL_CLASS_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_SCHOOL_CLASS_REPORTS }));
        next(
          setData({
            data: { schoolReports: action.payload },
            feature: GET_SCHOOL_CLASS_REPORTS,
          })
        );
        break;
      case `${GET_SCHOOL_CLASS_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_SCHOOL_CLASS_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_SCHOOL_CLASS_REPORTS_ERROR_MSG ??
              GET_SCHOOL_CLASS_REPORTS_ERROR_MSG,
            feature: GET_SCHOOL_CLASS_REPORTS,
          })
        );
        break;
      case GET_GENERAL_SCHOOL_CLASS_REPORTS:
        next(setLoader({ state: true, feature: GET_GENERAL_SCHOOL_CLASS_REPORTS }));
        next(
          apiRequest({
            method: "POST",
            url: GET_GENERAL_SCHOOL_CLASS_REPORTS_URL,
            feature: GET_GENERAL_SCHOOL_CLASS_REPORTS,
            body: action.payload,
          })
        );
        break;
      case `${GET_GENERAL_SCHOOL_CLASS_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_GENERAL_SCHOOL_CLASS_REPORTS }));
        next(
          setData({
            data: { generalSchoolClassReports: action.payload?.classesReportForAdmin },
            feature: GET_GENERAL_SCHOOL_CLASS_REPORTS,
          })
        );
        break;
      case `${GET_GENERAL_SCHOOL_CLASS_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_GENERAL_SCHOOL_CLASS_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_GENERAL_SCHOOL_CLASS_REPORTS_ERROR_MSG ??
              GET_GENERAL_SCHOOL_CLASS_REPORTS_ERROR_MSG,
            feature: GET_GENERAL_SCHOOL_CLASS_REPORTS,
          })
        );
        break;
      case GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS:
        next(setLoader({ state: true, feature: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS }));
        next(
          apiRequest({
            method: "POST",
            url: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS_URL,
            feature: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS,
            body: action.payload,
          })
        );
        break;
      case `${GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS }));
        next(
          setData({
            data: {
              generalSuborganizationGradeReports:
                action.payload?.subOrganizationGeneralReportForAdmin,
            },
            feature: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS,
          })
        );
        break;
      case `${GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS_ERROR_MSG ??
              GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS_ERROR_MSG,
            feature: GET_GENERAL_SUBORGANIZATION_GRADE_REPORTS,
          })
        );
        break;
      case GET_SCHOOL_CLASS_STUDENT_REPORTS:
        next(setLoader({ state: true, feature: GET_SCHOOL_CLASS_STUDENT_REPORTS }));
        next(
          apiRequest({
            method: "POST",
            url: GET_SCHOOL_CLASS_STUDENT_REPORTS_URL,
            feature: GET_SCHOOL_CLASS_STUDENT_REPORTS,
            body: action.payload,
          })
        );
        break;
      case `${GET_SCHOOL_CLASS_STUDENT_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_SCHOOL_CLASS_STUDENT_REPORTS }));
        next(
          setData({
            data: { schoolClassReports: action.payload },
            feature: GET_SCHOOL_CLASS_STUDENT_REPORTS,
          })
        );
        break;
      case `${GET_SCHOOL_CLASS_STUDENT_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_SCHOOL_CLASS_STUDENT_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_SCHOOL_CLASS_STUDENT_REPORTS_ERROR_MSG ??
              GET_SCHOOL_CLASS_STUDENT_REPORTS_ERROR_MSG,
            feature: GET_SCHOOL_CLASS_STUDENT_REPORTS,
          })
        );
        break;
      case DELETE_ASSIGNMENT:
        next(
          apiRequest({
            method: "GET",
            url: `${DELETE_ASSIGNMENT_URL}/${action.payload.assignmentId}`,
            feature: DELETE_ASSIGNMENT,
          })
        );
        break;
      case `${DELETE_ASSIGNMENT} ${API_SUCCESS}`:
        const copyOfAssignments = { ...data.assignments };
        delete copyOfAssignments[action.payload.assignmentId];
        next(
          setData({
            data: {
              assignments: copyOfAssignments,
            },
            feature: DELETE_ASSIGNMENT,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message: DELETE_ASSIGNMENT_SUCCESS_MSG,
            feature: DELETE_ASSIGNMENT,
          })
        );
        break;
      case `${DELETE_ASSIGNMENT} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message: DELETE_ASSIGNMENT_ERROR_MSG,
            feature: DELETE_ASSIGNMENT,
          })
        );
        break;
      case GET_ALL_ACADEMIC_YEAR_ITEMS:
        if (data.academicYearItems?.length > 0) {
          // Do nothing
        } else {
          next(setLoader({ state: true, feature: GET_ALL_ACADEMIC_YEAR_ITEMS }));
          next(
            apiRequest({
              method: "GET",
              url: GET_ALL_ACADEMIC_YEAR_ITEMS_URL,
              feature: GET_ALL_ACADEMIC_YEAR_ITEMS,
            })
          );
        }
        break;
      case `${GET_ALL_ACADEMIC_YEAR_ITEMS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_ALL_ACADEMIC_YEAR_ITEMS }));
        next(
          setData({
            data: {
              academicYearItems: action.payload?.academicCalendar
                ? Object.values(action.payload.academicCalendar)
                : [],
            },
            feature: GET_ALL_ACADEMIC_YEAR_ITEMS,
          })
        );
        break;
      case `${GET_ALL_ACADEMIC_YEAR_ITEMS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_ALL_ACADEMIC_YEAR_ITEMS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ALL_ACADEMIC_YEAR_ITEMS_ERROR_MSG ??
              GET_ALL_ACADEMIC_YEAR_ITEMS_ERROR_MSG,
            feature: GET_ALL_ACADEMIC_YEAR_ITEMS,
          })
        );
        break;
      case GET_USER_GRADES:
        if (data.userGrades?.length > 0) {
          // Do nothing
        } else {
          next(setLoader({ state: true, feature: GET_USER_GRADES }));
          next(
            apiRequest({
              method: "GET",
              url: GET_USER_GRADES_URL,
              feature: GET_USER_GRADES,
            })
          );
        }
        break;
      case `${GET_USER_GRADES} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_USER_GRADES }));
        next(
          setData({
            data: {
              userGrades: action.payload?.classes ?? [],
            },
            feature: GET_USER_GRADES,
          })
        );
        break;
      case `${GET_USER_GRADES} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_USER_GRADES }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: localizations?.GET_USER_GRADES_ERROR_MSG ?? GET_USER_GRADES_ERROR_MSG,
            feature: GET_USER_GRADES,
          })
        );
        break;

      case GET_ALL_ACADEMIC_YEAR_DETAILS:
        if (data.academicYearItems?.length > 0) {
          // Do nothing
        } else {
          next(setLoader({ state: true, feature: GET_ALL_ACADEMIC_YEAR_DETAILS }));
          next(
            apiRequest({
              method: "GET",
              url: GET_ALL_ACADEMIC_YEAR_DETAILS_URL,
              feature: GET_ALL_ACADEMIC_YEAR_DETAILS,
            })
          );
        }
        break;
      case `${GET_ALL_ACADEMIC_YEAR_DETAILS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_ALL_ACADEMIC_YEAR_DETAILS }));
        next(
          setData({
            data: {
              academicYearDetails: action.payload?.academicCalendarDetail
                ? Object.values(action.payload.academicCalendarDetail)
                : [],
            },
            feature: GET_ALL_ACADEMIC_YEAR_DETAILS,
          })
        );
        break;
      case `${GET_ALL_ACADEMIC_YEAR_DETAILS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_ALL_ACADEMIC_YEAR_DETAILS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_ALL_ACADEMIC_YEAR_DETAILS_ERROR_MSG ??
              GET_ALL_ACADEMIC_YEAR_DETAILS_ERROR_MSG,
            feature: GET_ALL_ACADEMIC_YEAR_DETAILS,
          })
        );
        break;

      default:
        break;
    }
  };
