import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  CaretDown,
  CaretUp,
  CheckCircle,
  Envelope,
  XCircle,
  PencilSimple,
  Check,
  FunnelSimple,
} from "phosphor-react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";

import SuiBox from "components/SuiBox";
import Alert from "custom/Alert";
import Footer from "custom/Footer";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import CollapsibleTable from "custom/Tables/CollapsibleTable";
import CollapsibleInnerTable from "custom/Tables/CollapsibleTable/CollapsibleInnerTable";
import Loading from "custom/Loading";
import FeedbackMessageDialog from "custom/FeedbackMessageDialog";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiCover from "components/SuiCover";

import {
  startAssignmentList,
  getAssignmentDetails,
  GET_ASSIGNMENT_DETAILS,
} from "redux/actions/pages";
import RecordTableCell from "../../../custom/RecordTableCell";
import DeleteAssignment from "../components/DeleteAssignment";

const AssignmentList = ({
  localizations,
  language,
  suborganization,
  startScreen,
  allAssignments,
  getDetails,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [openedAssignment, setOpenedAssignment] = useState(0);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageModalOpenedForUser, setMessageModalOpenedForUser] = useState(null);

  const [assignmentFilter, setAssignmentFilter] = useState("");

  useEffect(() => {
    if (suborganization > 0) {
      startScreen();
    }
  }, [suborganization]);

  useEffect(() => {
    if (location.state?.bookTitle) {
      setAssignmentFilter(location.state.bookTitle);
    }
  }, [location.state?.bookTitle]);

  useEffect(() => {
    if (location.state?.assignmentId > 0) {
      setOpenedAssignment(location.state?.assignmentId);
    }
  }, [location.state?.assignmentId]);

  useEffect(() => {
    if (openedAssignment > 0) {
      const opened = allAssignments?.find((as) => as.id === openedAssignment);
      if (opened) {
        if (!opened.users) {
          getDetails({ assignmentId: openedAssignment });
        }
      } else {
        // eslint-disable-next-line no-console
        console.error("Sth went wrong!");
      }
    }
  }, [openedAssignment]);

  const onFeedbackClick = (userId) => {
    setMessageModalOpenedForUser(userId);
    setMessageModalOpen(true);
  };

  const getTaskCompletion = (user, taskType) => {
    const task = user.tasks.find((t) => t.taskType === taskType);
    // task ta sadece id, taskType ve elementId var.
    if (!task) {
      return "N/A";
    }
    if (task.completed) {
      if (taskType === "RECORD") {
        return <RecordTableCell audioUrl={task.audioUrl} />;
      }
      return <CheckCircle size={28} color="#82D616" weight="duotone" />;
    }

    return <XCircle size={28} color="#3A416F" />;
  };

  const prepareAssignmentTableRows = () => {
    let assignmentList = [];

    if (assignmentFilter === "") {
      assignmentList = allAssignments;
    } else {
      assignmentList = allAssignments.filter((a) =>
        a.book.title
          .toLocaleLowerCase(language)
          .includes(assignmentFilter.toLocaleLowerCase(language))
      );
    }

    return assignmentList?.map((assignment) => ({
      id: assignment.id,
      hasBorder: true,
      collapseButton:
        openedAssignment === assignment.id ? (
          <CaretUp
            size={20}
            weight="regular"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setOpenedAssignment(0);
            }}
          />
        ) : (
          <CaretDown
            size={20}
            weight="regular"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setOpenedAssignment(assignment.id);
            }}
          />
        ),
      creationDate: assignment.creationDate?.split(" ")[0],
      dueDate: assignment.dueDate,
      book: (
        <SuiBox display="flex" alignItems="center" py={0.5}>
          {assignment.book?.coverImage && assignment.book?.coverImage !== "" ? (
            <SuiBox mr={2}>
              <SuiCover
                src={assignment.book?.coverImage}
                name={assignment.book?.title}
                variant="square"
                size="sm"
              />
            </SuiBox>
          ) : null}
          <SuiTypography
            variant="button"
            fontWeight="medium"
            sx={{ width: "max-content", color: "#8392ab" }}
          >
            {assignment.book?.title ?? "-"}
          </SuiTypography>
        </SuiBox>
      ),
      // assignment.book.title,
      read: assignment.read ? <Check size={24} weight="duotone" color="gray" /> : "N/A",
      listen: assignment.listen ? <Check size={24} weight="duotone" color="gray" /> : "N/A",
      record: assignment.record ? <Check size={24} weight="duotone" color="gray" /> : "N/A",
      quiz: assignment.quiz ? <Check size={24} weight="duotone" color="gray" /> : "N/A",
      material: assignment.material ? <Check size={24} weight="duotone" color="gray" /> : "N/A",
      actions: (
        <SuiBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SuiBox>
            <SuiButton
              iconOnly
              circular
              color="info"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/assignment/edit",
                  state: { assignmentId: assignment.id },
                });
              }}
            >
              <PencilSimple size={32} weight="duotone" color="white" />
            </SuiButton>
          </SuiBox>
          <DeleteAssignment assignmentId={assignment.id} />
        </SuiBox>
      ),
    }));
  };

  return (
    <>
      <Alert />
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item sm={7} xs={12}>
              <h3>{localizations?.ASSIGNMENT_LIST_HEADER}</h3>
            </Grid>
            <Grid item sm={5} xs={12}>
              <SuiInput
                size="small"
                type="search"
                placeholder={localizations?.ASSIGNMENT_FILTER_INPUT_PLACEHOLDER ?? "Görev Filtrele"}
                icon={{ component: <FunnelSimple />, direction: "left" }}
                value={assignmentFilter}
                onChange={(e) => {
                  setAssignmentFilter(e.target.value);
                }}
              />{" "}
            </Grid>
            <Grid item lg={12} mt={3} xs={12}>
              <CollapsibleTable
                columns={[
                  { name: "collapseButton", align: "center", title: "" },
                  { name: "book", align: "left", title: localizations?.TABLE_HEADER_BOOK },
                  {
                    name: "creationDate",
                    align: "left",
                    title: localizations?.TABLE_HEADER_CREATION_DATE,
                  },
                  { name: "dueDate", align: "left", title: localizations?.TABLE_HEADER_DUE_DATE },
                  { name: "read", align: "center", title: localizations?.TABLE_HEADER_READING },
                  { name: "listen", align: "center", title: localizations?.TABLE_HEADER_LISTENING },
                  { name: "record", align: "center", title: localizations?.TABLE_HEADER_RECORDING },
                  { name: "quiz", align: "center", title: localizations?.TABLE_HEADER_QUIZ },
                  {
                    name: "material",
                    align: "center",
                    title: localizations?.TABLE_HEADER_MATERIAL,
                  },
                  {
                    name: "actions",
                    align: "center",
                    title: localizations?.TABLE_HEADER_ACTIONS,
                  },
                ]}
                rows={prepareAssignmentTableRows()}
                collapseElement={() => [
                  openedAssignment,
                  <div style={{ position: "relative" }}>
                    <Loading identifier={GET_ASSIGNMENT_DETAILS} />
                    <CollapsibleInnerTable
                      innerHeader={localizations?.ASSIGNMENT_DETAILS ?? "Görev Takip"}
                      columns={[
                        { name: "name", align: "left", title: localizations?.TABLE_HEADER_NAME },
                        {
                          name: "read",
                          align: "center",
                          title: localizations?.TABLE_HEADER_READING,
                        },
                        {
                          name: "listen",
                          align: "center",
                          title: localizations?.TABLE_HEADER_LISTENING,
                        },
                        {
                          name: "record",
                          align: "center",
                          title: localizations?.TABLE_HEADER_RECORDING,
                        },
                        { name: "quiz", align: "center", title: localizations?.TABLE_HEADER_QUIZ },
                        {
                          name: "material",
                          align: "center",
                          title: localizations?.TABLE_HEADER_MATERIAL,
                        },
                        {
                          name: "feedback",
                          align: "center",
                          title: localizations?.TABLE_HEADER_FEEDBACK,
                        },
                      ]}
                      rows={allAssignments
                        ?.find((as) => as.id === openedAssignment)
                        ?.users?.sort((a, b) =>
                          `${a.name} ${a.surname}`.localeCompare(`${b.name} ${b.surname}`, "TR")
                        )
                        ?.map((user) => ({
                          name: [user.avatarUrl, `${user.name} ${user.surname}`],
                          read: getTaskCompletion(user, "READ"),
                          listen: getTaskCompletion(user, "LISTEN"),
                          record: getTaskCompletion(user, "RECORD"),
                          quiz: getTaskCompletion(user, "QUIZ"),
                          material: getTaskCompletion(user, "MATERIAL"),
                          feedback: localizations?.FEEDBACK_BTN ? (
                            <SuiButton
                              circular
                              color="info"
                              onClick={() => onFeedbackClick(user.id)}
                            >
                              {localizations?.FEEDBACK_BTN}
                            </SuiButton>
                          ) : (
                            <SuiButton
                              iconOnly
                              circular
                              color="info"
                              onClick={() => onFeedbackClick(user.id)}
                            >
                              <Envelope size={28} weight="duotone" />
                            </SuiButton>
                          ),
                        }))}
                    />
                  </div>,
                ]}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <FeedbackMessageDialog
          onClose={() => {
            setMessageModalOpen(false);
            setMessageModalOpenedForUser(null);
          }}
          open={messageModalOpen}
          messages={
            allAssignments
              ?.find((as) => as.id === openedAssignment)
              ?.users?.find((us) => us.id === messageModalOpenedForUser)?.messages ?? []
          }
          userId={messageModalOpenedForUser}
          assignmentId={openedAssignment}
        />
        <Footer />
      </DashboardLayout>
    </>
  );
};

AssignmentList.defaultProps = {
  localizations: null,
  language: "TR",
  allAssignments: [],
  suborganization: 0,
};

AssignmentList.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  language: PropTypes.string,
  suborganization: PropTypes.number,
  startScreen: PropTypes.func.isRequired,
  allAssignments: PropTypes.arrayOf(PropTypes.any),
  getDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  language: state.localization?.language,
  suborganization: state.header?.suborganization,
  allAssignments:
    state.data.assignments !== undefined && state.data.assignments !== null
      ? Object.values(state.data.assignments)?.sort(
          (a, b) =>
            parseInt(b?.dueDate?.split(".")?.reverse()?.join(""), 10) -
            parseInt(a?.dueDate?.split(".")?.reverse()?.join(""), 10)
        )
      : [],
});

const mapDispatchToProps = {
  startScreen: startAssignmentList,
  getDetails: getAssignmentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentList);
